import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Container from '@material-ui/core/Container';
import {Button, makeStyles} from "@material-ui/core";
import Logo from '../assets/fitnoo.png';
import {Link, useHistory} from "react-router-dom";
import Footer from "./Footer";

function ElevationScroll(props) {
  const {children, window} = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const useStyles = makeStyles({
  logoImg: {
    backgroundImage: `url(${Logo})`,
    height: '2rem',
    width: '8rem',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  btn: {
    margin: '0 .5rem'
  },
  siteContainer: {
    height: '100%',
    display: 'grid',
    gridTemplateRows: '1fr auto'
  }
});

export default function Layout(props) {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={classes.siteContainer}>
      <div>
        <CssBaseline/>
        <ElevationScroll {...props}>
          <AppBar color={"inherit"}>
            <Toolbar className={classes.header}>
              <Link to={"/"}>
                <div className={classes.logoImg}/>
              </Link>
              <div>
                <Button className={classes.btn} variant={"outlined"} color={"primary"}
                        onClick={() => window.location.href = "https://personal.fitnoo.app"}>Login</Button>
                <Button onClick={() => history.push("/sign-up")} className={classes.btn} variant={"contained"}
                        color={"primary"} disableElevation>Sign up</Button>
              </div>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Toolbar/>
        <Container style={{marginBottom: '1rem'}}>
          {props.children}
        </Container>
      </div>
      <Footer/>
    </div>
  );
}
