import {createMuiTheme, ThemeProvider} from "@material-ui/core";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import SignUp from "./pages/SignUp";
import Personal from "./pages/Personal";
import Gym from "./pages/Gym";
import Studio from "./pages/Studio";
import NotFound from "./pages/NotFound";

const theme = createMuiTheme({

  palette: {
    primary: {
      main: "#4b4",
      dark: "#008a10",
      light: "#7bee73",
      contrastText: "#fefefe"
    },
    secondary: {
      main: "#ba437f",
      dark: "#870453",
      light: "#ef74ae",
      contrastText: "#fefefe"
    },
    background: {
      default: '#fff'
    }
  },
  typography: {
    fontFamily: 'Raleway',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700
  }
})

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Layout>
          <Switch>
            <Route exact path={"/"}>
              <Home />
            </Route>
            <Route exact path={"/sign-up"}>
              <SignUp/>
            </Route>
            <Route exact path={"/personal"}>
              <Personal />
            </Route>
            <Route exact path={"/studio"}>
              <Studio />
            </Route>
            <Route exact path={"/gym"}>
              <Gym />
            </Route>
            <Route path={"*"}>
              <NotFound />
            </Route>
          </Switch>
        </Layout>
      </Router>
    </ThemeProvider>
  );
}

export default App;
