import React from 'react';
import styled from 'styled-components';

const Paragraph = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;
`

const Placeholder = styled.span`
  display: flex;
  width: 10vw;
`

const Container  = styled.div`
  padding: 2rem 1rem 1rem;
`

const Claim = () => {
  return (
    <Container>
      <Paragraph>
        <span>The personal trainer</span>
        <Placeholder/>
      </Paragraph>
      <Paragraph>
        <Placeholder/>
        <span>inside your pocket</span>
      </Paragraph>
    </Container>
  )
}

export default Claim;
