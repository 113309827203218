import React from "react";
import landingImg from '../assets/landing.jpg';
import styled from 'styled-components';
import Claim from "../components/home/Claim";
import Products from "../components/home/Products";

const LandingImg = styled.div`
  background-image: url(${landingImg});
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  min-height: 50vh;
  background-position: center;
`

const LandingPage = styled.div`
  min-height: 90vh;
`

export default function Home() {
  document.title = "fitnoo";
  return(
    <div>
      <LandingPage>
        <Claim />
        <LandingImg />
      </LandingPage>
      <Products />
    </div>
  );
}
