import React from "react";
import {Typography} from "@material-ui/core";

export default function  Footer() {
  return(
    <div style={{backgroundColor: '#2e2e2e', color: '#fafafa', paddingBottom: '2rem', paddingTop: '1rem'}}>
      <Typography style={{textAlign: 'center'}}>&copy;{new Date().getFullYear()} fitnoo</Typography>
    </div>
  );
}
