import React, {useState} from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import {useHistory} from 'react-router-dom';
import {gql, useMutation} from "@apollo/client";

const useStyles = makeStyles({
  field: {
    margin: '.66rem 0',
    display: 'block'
  },
  notification: {
    fontSize: "small",
    marginBottom: '1.25rem',
    textAlign: 'center'
  }
});

const ADD_USER = gql`
    mutation AddUser($email: String!, $username: String!, $password: String!) {
        createUser(email: $email, username: $username, password: $password) {
            id
            email
            username
        }
    }
`;

export default function SignUp() {
  document.title = "fitnoo | Sign up";
  const classes = useStyles();
  const history = useHistory();

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [verifyPasswd, setVerifyPasswd] = useState('');

  const [usernameError, setUsernameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [verifyPasswdError, setVerifyPasswdError] = useState(false);

  const [createUser, {loading, error, data}] = useMutation(ADD_USER);


  const handleSubmit = () => {
    setUsernameError(false);
    setEmailError(false);
    setPasswordError(false);
    setVerifyPasswdError(false);

    if (!username) setUsernameError(true);
    if (!email) setEmailError(true);
    if (!password) setPasswordError(true);
    if (!verifyPasswd) setVerifyPasswdError(true);

    if (password !== verifyPasswd && !passwordError && !verifyPasswdError) {
      setVerifyPasswdError(true);
      setPasswordError(true);
    } else {
      createUser({variables: {email, username, password}})
        .then(r => console.log(r))
        .catch(err => console.log(err.message))
        .finally(() => {
          setPassword('');
          setVerifyPasswd('');
        });
    }
  }

  return (
    <Container style={{flexGrow: 1}}>
      <Typography variant={"h5"} component={"h3"} style={{margin: '1rem 0', textAlign: 'center'}}>Create your account</Typography>
      <Grid container justify={"center"}>
        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Card elevation={3}>
            <CardContent>
              {loading && <Typography className={classes.notification}>Loading...</Typography>}
              {error && <Typography color={"error"} className={classes.notification}>{error.message}</Typography>}
              {data && <Typography color={"primary"} className={classes.notification}>Account created successfully.<br />Please check your emails for further steps.</Typography>}
              <TextField
                disabled={loading}
                className={classes.field}
                label={"Username"}
                variant={"outlined"}
                required
                fullWidth
                size={"small"}
                onChange={(e) => setUsername(e.target.value)}
                error={usernameError}
              />
              <TextField
                disabled={loading}
                type={"email"}
                className={classes.field}
                label={"E-Mail"}
                variant={"outlined"}
                required
                fullWidth
                size={"small"}
                onChange={(e) => setEmail(e.target.value)}
                error={emailError}
              />
              <TextField
                value={password}
                disabled={loading}
                type={"password"}
                className={classes.field}
                label={"Password"}
                variant={"outlined"}
                required
                fullWidth
                size={"small"}
                onChange={(e) => setPassword(e.target.value)}
                error={passwordError}
              />
              <TextField
                value={verifyPasswd}
                disabled={loading}
                type={"password"}
                className={classes.field}
                label={"Retype Password"}
                variant={"outlined"}
                required
                fullWidth
                size={"small"}
                onChange={(e) => setVerifyPasswd(e.target.value)}
                error={verifyPasswdError}
              />
            </CardContent>
            <CardActions style={{display: "flex", justifyContent: "space-between"}}>
              <Button onClick={() => history.push("/")}>Cancel</Button>
              <Button variant={"contained"} color={"primary"} onClick={handleSubmit}>Sign Up</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
