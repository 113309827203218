import React from "react";
import {Container, Typography} from "@material-ui/core";
import {Link} from "react-router-dom";

export default function NotFound() {
  document.title = "404 - Not Found"
  return (
    <Container>
      <Typography variant={"h4"} style={{textAlign: 'center'}}>Oooops...</Typography>
      <br />
      <br />
      <Typography>This site could not be found</Typography>
      <Link to={"/"} style={{textDecoration: 'none', color: 'dodgerblue'}}><Typography>Return to home</Typography></Link>
    </Container>
  );
}
