import React from "react";
import {Card, CardContent, CardMedia, Typography} from "@material-ui/core";
import styled from "styled-components";
import {useHistory} from 'react-router-dom';

const ProductsContainer = styled.div`
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const ProductCard = ({name, image, route}) => {
  const history = useHistory();

  return (
    <Card style={{width: '12rem', backgroundColor: '#fafafa', cursor: 'pointer'}} elevation={3}
          onClick={() => history.push(route)}>
      <CardMedia
        style={{height: '5rem', backgroundSize: 'contain'}}
        image={image}
      />
      <CardContent>
        <Typography variant={"h6"} component={"h4"} style={{textAlign: 'center'}}>{name}</Typography>
      </CardContent>
    </Card>
  );
};

export default function Products() {
  return (
    <ProductsContainer>
      <ProductCard name={"Personal"} image={"https://cdn.coda.io/icons/svg/color/acrobatics.svg"} route={"/personal"}/>
      <ProductCard name={"Studio"} image={"https://cdn.coda.io/icons/svg/color/pilates.svg"} route={"/studio"}/>
      <ProductCard name={"Gym"} image={"https://cdn.coda.io/icons/svg/color/bench-press.svg"} route={"/gym"}/>
    </ProductsContainer>
  );
}
